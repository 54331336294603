import React, { useEffect, useState } from "react";
import "./assets/vacancy.css";
import { useTranslation } from 'react-i18next';

import http from "../ui/Services";
import { PageTitle } from "../ui/PageTitle";
import { Container, Row } from "react-bootstrap";
import { region } from "../../host";
export default function Vacancy() {
    const {t} = useTranslation();
  const [loader, setLoader] = useState(true)
  const [data, setData] = useState<any>(null)
  const [regionData, setregionData] = useState<any>(null)
 
  useEffect(()=>{
    http.get<any>(`/GetApi/GetVacancy/${region}`).then((res) => {console.log(res.data); setData(res.data);
      http.get(`GetApi/GetRegionContacts/${region}`)
      .then((res) => {
          var a=[]
          a.push(res.data.filter((data: any) => data.regionId === region))
          setregionData(a[0][0])
          console.log(a)
          setTimeout(()=>{
            setLoader(false)
        }, 0)
        })
      .catch(e => console.log(e))
    })
    .catch(e => console.log(e))
  },[])
  return (
    <div>
      {loader?<div className="loaderG">
    <div className="befG">
<img src="https://vatanparvar.uz/Files/images/logo.gif" alt="..."/>
</div>

</div>
:''}
      <br/>
        <PageTitle title={t('vacancy')} />
        <Container>
        
        <div className="vacancy">
        <div className="vacancy_title"><h1>{t("Xodimlar bilan ishlash bo'limi telefon raqami")} :</h1>
          <a href="tel: +998 (71) 234-85-21">{regionData!==null && regionData.kadrPhone!==null?regionData.kadrPhone:""}</a>
          </div>
          {data!==null?data.map((item:any, key:any)=>{
                   return(<div className="vacancy_item">
                    <h1>{t('check')?item.nameUz:item.nameRu}</h1>
                    <p>{item.count}</p>
                   </div>)
          }):<></>}
          {data!==null && data.length===0?<h1 className="error_t">{t("Bizda bo'sh ish o'rni mavjud emas")}</h1>:<></>}
          
        </div>
        </Container>
    </div>
  )
}

import React from 'react'
import { Helmet } from 'react-helmet-async';
import { api, regionA_nameUz, region_nameUz } from './host';

export default function reactSeo() {
  return (
    <Helmet>
    <title>{region_nameUz.replaceAll("<br/>", "").replaceAll("<strong>", "").replaceAll("</strong>", "").toLocaleUpperCase()}</title>
    <meta name="description" content={`${regionA_nameUz}da yashovchi 18 yoshdan katta fuqarolar haydovchilik guvohnomasi olish uchun “Vatanparvar” tashkiloti avtomaktablariga murojaat qilishlari mumkin.`}></meta>
    <link rel="canonical" href={api} />    
              </Helmet>
  )
}

import React, { useEffect, useState } from 'react'
import { Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { PageTitle } from '../../ui/PageTitle'
import style from './assets/Document.module.css'
import docs from './assets/docs.png'
import http from '../../ui/Services'
import { api } from '../../../host'
export default function Document() {
    const [data, setData]=useState<any>(null)
    const {t}=useTranslation()
    useEffect(()=>{
        http.get('GetApi/GetDocuments').then(res=>{
           setData(res.data)
        })
    },[])
  return (
    <div className="hujjatlar">
      <div className="container">
        <PageTitle title={t('Yoshlarga hujjatlar')} />
       <Row className={style.row}>
      {data!=null?data.map((item:any, key:any)=>{
        return( <Col key={key} className={style.col} lg={12} md={24}>
            <a target="_blank" href={`https://vatanparvar.uz/Files/Documents/${t('check') || (!t('check') && item.fileRu==null)?item.fileUz:item.fileRu}`} className={style.docs_card}>
             <div className={style.card_img}><img src={docs}/></div>
             <p>{t("check")?item.titleUz:item.titleRu}</p>
             </a>   
        </Col>)
      }):''}
      
      
          
       </Row>
        </div>
        </div>
  )
}

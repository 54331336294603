
export const url=window.location.href.indexOf('andijon.vatanparvar.uz')!==-1?"https://andijon.vatanparvar.uz/Files/News":
window.location.href.indexOf('buxoro.vatanparvar.uz')!==-1?"https://buxoro.vatanparvar.uz/Files/News":
window.location.href.indexOf('fargona.vatanparvar.uz')!==-1?"https://fargona.vatanparvar.uz/Files/News":
window.location.href.indexOf('qoraqalpoq.vatanparvar.uz')!==-1?"https://qoraqalpoq.vatanparvar.uz/Files/News":
window.location.href.indexOf('qashqadaryo.vatanparvar.uz')!==-1?"https://qashqadaryo.vatanparvar.uz/Files/News":
window.location.href.indexOf('xorazm.vatanparvar.uz')!==-1?"https://xorazm.vatanparvar.uz/Files/News":
window.location.href.indexOf('samarqand.vatanparvar.uz')!==-1?"https://samarqand.vatanparvar.uz/Files/News":
window.location.href.indexOf('surxondaryo.vatanparvar.uz')!==-1?"https://surxondaryo.vatanparvar.uz/Files/News":
window.location.href.indexOf('toshkent.vatanparvar.uz')!==-1?"https://toshkent.vatanparvar.uz/Files/News":
window.location.href.indexOf('toshvil.vatanparvar.uz')!==-1?"https://toshvil.vatanparvar.uz/Files/News":
window.location.href.indexOf('navoiy.vatanparvar.uz')!==-1?"https://navoiy.vatanparvar.uz/Files/News":
window.location.href.indexOf('jizzax.vatanparvar.uz')!==-1?"https://jizzax.vatanparvar.uz/Files/News":
window.location.href.indexOf('namangan.vatanparvar.uz')!==-1?"https://namangan.vatanparvar.uz/Files/News":
window.location.href.indexOf('sirdaryo.vatanparvar.uz')!==-1?"https://sirdaryo.vatanparvar.uz/Files/News":
"https://buxoro.vatanparvar.uz/Files/News";

export const urlV="https://vatanparvar.uz/Files/News"
export const urlSJ="https://vatanparvar.uz"


export const api=window.location.href.indexOf('andijon.vatanparvar.uz')!==-1?"https://andijon.vatanparvar.uz/":
window.location.href.indexOf('buxoro.vatanparvar.uz')!==-1?"https://buxoro.vatanparvar.uz/":
window.location.href.indexOf('fargona.vatanparvar.uz')!==-1?"https://fargona.vatanparvar.uz/":
window.location.href.indexOf('qoraqalpoq.vatanparvar.uz')!==-1?"https://qoraqalpoq.vatanparvar.uz/":
window.location.href.indexOf('qashqadaryo.vatanparvar.uz')!==-1?"https://qashqadaryo.vatanparvar.uz/":
window.location.href.indexOf('xorazm.vatanparvar.uz')!==-1?"https://xorazm.vatanparvar.uz/":
window.location.href.indexOf('samarqand.vatanparvar.uz')!==-1?"https://samarqand.vatanparvar.uz/":
window.location.href.indexOf('surxondaryo.vatanparvar.uz')!==-1?"https://surxondaryo.vatanparvar.uz/":
window.location.href.indexOf('toshkent.vatanparvar.uz')!==-1?"https://toshkent.vatanparvar.uz/":
window.location.href.indexOf('toshvil.vatanparvar.uz')!==-1?"https://toshvil.vatanparvar.uz/":
window.location.href.indexOf('navoiy.vatanparvar.uz')!==-1?"https://navoiy.vatanparvar.uz/":
window.location.href.indexOf('jizzax.vatanparvar.uz')!==-1?"https://jizzax.vatanparvar.uz/":
window.location.href.indexOf('namangan.vatanparvar.uz')!==-1?"https://namangan.vatanparvar.uz/":
window.location.href.indexOf('sirdaryo.vatanparvar.uz')!==-1?"https://sirdaryo.vatanparvar.uz/":
"https://buxoro.vatanparvar.uz/";




export const urlFoto=window.location.href.indexOf('andijon.vatanparvar.uz')!==-1?"https://andijon.vatanparvar.uz/Files/Photogallery":
window.location.href.indexOf('buxoro.vatanparvar.uz')!==-1?"https://buxoro.vatanparvar.uz/Files/Photogallery":
window.location.href.indexOf('fargona.vatanparvar.uz')!==-1?"https://fargona.vatanparvar.uz/Files/Photogallery":
window.location.href.indexOf('qoraqalpoq.vatanparvar.uz')!==-1?"https://qoraqalpoq.vatanparvar.uz/Files/Photogallery":
window.location.href.indexOf('qashqadaryo.vatanparvar.uz')!==-1?"https://qashqadaryo.vatanparvar.uz/Files/Photogallery":
window.location.href.indexOf('xorazm.vatanparvar.uz')!==-1?"https://xorazm.vatanparvar.uz/Files/Photogallery":
window.location.href.indexOf('samarqand.vatanparvar.uz')!==-1?"https://samarqand.vatanparvar.uz/Files/Photogallery":
window.location.href.indexOf('surxondaryo.vatanparvar.uz')!==-1?"https://surxondaryo.vatanparvar.uz/Files/Photogallery":
window.location.href.indexOf('toshkent.vatanparvar.uz')!==-1?"https://toshkent.vatanparvar.uz/Files/Photogallery":
window.location.href.indexOf('toshvil.vatanparvar.uz')!==-1?"https://toshvil.vatanparvar.uz/Files/Photogallery":
window.location.href.indexOf('navoiy.vatanparvar.uz')!==-1?"https://navoiy.vatanparvar.uz/Files/Photogallery":
window.location.href.indexOf('jizzax.vatanparvar.uz')!==-1?"https://jizzax.vatanparvar.uz/Files/Photogallery":
window.location.href.indexOf('namangan.vatanparvar.uz')!==-1?"https://namangan.vatanparvar.uz/Files/Photogallery":
window.location.href.indexOf('sirdaryo.vatanparvar.uz')!==-1?"https://sirdaryo.vatanparvar.uz/Files/Photogallery":
"https://buxoro.vatanparvar.uz/Files/Photogallery";


export const urlStat=window.location.href.indexOf('andijon.vatanparvar.uz')!==-1?"https://andijon.vatanparvar.uz/Files/StatisticElements":
window.location.href.indexOf('buxoro.vatanparvar.uz')!==-1?"https://buxoro.vatanparvar.uz/Files/StatisticElements":
window.location.href.indexOf('fargona.vatanparvar.uz')!==-1?"https://fargona.vatanparvar.uz/Files/StatisticElements":
window.location.href.indexOf('qoraqalpoq.vatanparvar.uz')!==-1?"https://qoraqalpoq.vatanparvar.uz/Files/StatisticElements":
window.location.href.indexOf('qashqadaryo.vatanparvar.uz')!==-1?"https://qashqadaryo.vatanparvar.uz/Files/StatisticElements":
window.location.href.indexOf('xorazm.vatanparvar.uz')!==-1?"https://xorazm.vatanparvar.uz/Files/StatisticElements":
window.location.href.indexOf('samarqand.vatanparvar.uz')!==-1?"https://samarqand.vatanparvar.uz/Files/StatisticElements":
window.location.href.indexOf('surxondaryo.vatanparvar.uz')!==-1?"https://surxondaryo.vatanparvar.uz/Files/StatisticElements":
window.location.href.indexOf('toshkent.vatanparvar.uz')!==-1?"https://toshkent.vatanparvar.uz/Files/StatisticElements":
window.location.href.indexOf('toshvil.vatanparvar.uz')!==-1?"https://toshvil.vatanparvar.uz/Files/StatisticElements":
window.location.href.indexOf('navoiy.vatanparvar.uz')!==-1?"https://navoiy.vatanparvar.uz/Files/StatisticElements":
window.location.href.indexOf('jizzax.vatanparvar.uz')!==-1?"https://jizzax.vatanparvar.uz/Files/StatisticElements":
window.location.href.indexOf('namangan.vatanparvar.uz')!==-1?"https://namangan.vatanparvar.uz/Files/StatisticElements":
window.location.href.indexOf('sirdaryo.vatanparvar.uz')!==-1?"https://sirdaryo.vatanparvar.uz/Files/StatisticElements":
"https://buxoro.vatanparvar.uz/Files/StatisticElements";


export const urlPer=window.location.href.indexOf('andijon.vatanparvar.uz')!==-1?"https://andijon.vatanparvar.uz/Files/Personal":
window.location.href.indexOf('buxoro.vatanparvar.uz')!==-1?"https://buxoro.vatanparvar.uz/Files/Personal":
window.location.href.indexOf('fargona.vatanparvar.uz')!==-1?"https://fargona.vatanparvar.uz/Files/Personal":
window.location.href.indexOf('qoraqalpoq.vatanparvar.uz')!==-1?"https://qoraqalpoq.vatanparvar.uz/Files/Personal":
window.location.href.indexOf('qashqadaryo.vatanparvar.uz')!==-1?"https://qashqadaryo.vatanparvar.uz/Files/Personal":
window.location.href.indexOf('xorazm.vatanparvar.uz')!==-1?"https://xorazm.vatanparvar.uz/Files/Personal":
window.location.href.indexOf('samarqand.vatanparvar.uz')!==-1?"https://samarqand.vatanparvar.uz/Files/Personal":
window.location.href.indexOf('surxondaryo.vatanparvar.uz')!==-1?"https://surxondaryo.vatanparvar.uz/Files/Personal":
window.location.href.indexOf('toshkent.vatanparvar.uz')!==-1?"https://toshkent.vatanparvar.uz/Files/Personal":
window.location.href.indexOf('toshvil.vatanparvar.uz')!==-1?"https://toshvil.vatanparvar.uz/Files/Personal":
window.location.href.indexOf('navoiy.vatanparvar.uz')!==-1?"https://navoiy.vatanparvar.uz/Files/Personal":
window.location.href.indexOf('jizzax.vatanparvar.uz')!==-1?"https://jizzax.vatanparvar.uz/Files/Personal":
window.location.href.indexOf('namangan.vatanparvar.uz')!==-1?"https://namangan.vatanparvar.uz/Files/Personal":
window.location.href.indexOf('sirdaryo.vatanparvar.uz')!==-1?"https://sirdaryo.vatanparvar.uz/Files/Personal":
"https://buxoro.vatanparvar.uz/Files/Personal";


export const region=window.location.href.indexOf('andijon.vatanparvar.uz')!==-1?2:
window.location.href.indexOf('buxoro.vatanparvar.uz')!==-1?3:
window.location.href.indexOf('fargona.vatanparvar.uz')!==-1?12:
window.location.href.indexOf('qoraqalpoq.vatanparvar.uz')!==-1?1:
window.location.href.indexOf('qashqadaryo.vatanparvar.uz')!==-1?5:
window.location.href.indexOf('xorazm.vatanparvar.uz')!==-1?13:
window.location.href.indexOf('samarqand.vatanparvar.uz')!==-1?8:
window.location.href.indexOf('surxondaryo.vatanparvar.uz')!==-1?9:
window.location.href.indexOf('toshkent.vatanparvar.uz')!==-1?14:
window.location.href.indexOf('toshvil.vatanparvar.uz')!==-1?11:
window.location.href.indexOf('navoiy.vatanparvar.uz')!==-1?6:
window.location.href.indexOf('jizzax.vatanparvar.uz')!==-1?4:
window.location.href.indexOf('namangan.vatanparvar.uz')!==-1?7:
window.location.href.indexOf('sirdaryo.vatanparvar.uz')!==-1?10:
2;


export const regionI=window.location.href.indexOf('andijon.vatanparvar.uz')!==-1?1703:
window.location.href.indexOf('buxoro.vatanparvar.uz')!==-1?1706:
window.location.href.indexOf('fargona.vatanparvar.uz')!==-1?1730:
window.location.href.indexOf('qoraqalpoq.vatanparvar.uz')!==-1?1735:
window.location.href.indexOf('qashqadaryo.vatanparvar.uz')!==-1?1710:
window.location.href.indexOf('xorazm.vatanparvar.uz')!==-1?1733:
window.location.href.indexOf('samarqand.vatanparvar.uz')!==-1?1718:
window.location.href.indexOf('surxondaryo.vatanparvar.uz')!==-1?1722:
window.location.href.indexOf('toshkent.vatanparvar.uz')!==-1?1726:
window.location.href.indexOf('toshvil.vatanparvar.uz')!==-1?1727:
window.location.href.indexOf('navoiy.vatanparvar.uz')!==-1?1712:
window.location.href.indexOf('jizzax.vatanparvar.uz')!==-1?1708:
window.location.href.indexOf('namangan.vatanparvar.uz')!==-1?1714:
window.location.href.indexOf('sirdaryo.vatanparvar.uz')!==-1?1724:
1703;

export const urlImg="https://kadr.vatanparvar.uz/img/personal/"

export const region_nameUz=window.location.href.indexOf('andijon.vatanparvar.uz')!==-1?"O’ZBEKISTON RESPUBLIKASI <br/> MUDOFAASIGA KO’MAKLASHUVCHI <br/> “VATANPARVAR” TASHKILOTI <br/> <strong>Andijon viloyati KENGASHI</strong>":
window.location.href.indexOf('buxoro.vatanparvar.uz')!==-1?"O’ZBEKISTON RESPUBLIKASI <br/> MUDOFAASIGA KO’MAKLASHUVCHI <br/> “VATANPARVAR” TASHKILOTI <br/> <strong>Buxoro viloyati KENGASHI</strong>":
window.location.href.indexOf('fargona.vatanparvar.uz')!==-1?"O’ZBEKISTON RESPUBLIKASI <br/> MUDOFAASIGA KO’MAKLASHUVCHI <br/> “VATANPARVAR” TASHKILOTI <br/> <strong>Fargona viloyati KENGASHI</strong>":
window.location.href.indexOf('qoraqalpoq.vatanparvar.uz')!==-1?"O’ZBEKISTON RESPUBLIKASI <br/> MUDOFAASIGA KO’MAKLASHUVCHI <br/> “VATANPARVAR” TASHKILOTI <br/> <strong>QORAQALPOG'ISTON RESPUBLIKASI KENGASHI</strong>":
window.location.href.indexOf('qashqadaryo.vatanparvar.uz')!==-1?"O’ZBEKISTON RESPUBLIKASI <br/> MUDOFAASIGA KO’MAKLASHUVCHI <br/> “VATANPARVAR” TASHKILOTI <br/> <strong>Qashqadaryo viloyati KENGASHI</strong>":
window.location.href.indexOf('xorazm.vatanparvar.uz')!==-1?"O’ZBEKISTON RESPUBLIKASI <br/> MUDOFAASIGA KO’MAKLASHUVCHI <br/> “VATANPARVAR” TASHKILOTI <br/> <strong>Xorazm viloyati KENGASHI</strong>":
window.location.href.indexOf('samarqand.vatanparvar.uz')!==-1?"O’ZBEKISTON RESPUBLIKASI <br/> MUDOFAASIGA KO’MAKLASHUVCHI <br/> “VATANPARVAR” TASHKILOTI <br/> <strong>Samarqand viloyati KENGASHI</strong>":
window.location.href.indexOf('surxondaryo.vatanparvar.uz')!==-1?"O’ZBEKISTON RESPUBLIKASI <br/> MUDOFAASIGA KO’MAKLASHUVCHI <br/> “VATANPARVAR” TASHKILOTI <br/> <strong>Surxondaryo viloyati KENGASHI</strong>":
window.location.href.indexOf('toshkent.vatanparvar.uz')!==-1?"O’ZBEKISTON RESPUBLIKASI <br/> MUDOFAASIGA KO’MAKLASHUVCHI <br/> “VATANPARVAR” TASHKILOTI <br/> <strong>Toshkent shahri KENGASHI</strong>":
window.location.href.indexOf('toshvil.vatanparvar.uz')!==-1?"O’ZBEKISTON RESPUBLIKASI <br/> MUDOFAASIGA KO’MAKLASHUVCHI <br/> “VATANPARVAR” TASHKILOTI <br/> <strong>Toshkent viloyati KENGASHI</strong>":
window.location.href.indexOf('navoiy.vatanparvar.uz')!==-1?"O’ZBEKISTON RESPUBLIKASI <br/> MUDOFAASIGA KO’MAKLASHUVCHI <br/> “VATANPARVAR” TASHKILOTI <br/> <strong>Navoiy viloyati KENGASHI</strong>":
window.location.href.indexOf('jizzax.vatanparvar.uz')!==-1?"O’ZBEKISTON RESPUBLIKASI <br/> MUDOFAASIGA KO’MAKLASHUVCHI <br/> “VATANPARVAR” TASHKILOTI <br/> <strong>Jizzax viloyati KENGASHI</strong>":
window.location.href.indexOf('namangan.vatanparvar.uz')!==-1?"O’ZBEKISTON RESPUBLIKASI <br/> MUDOFAASIGA KO’MAKLASHUVCHI <br/> “VATANPARVAR” TASHKILOTI <br/> <strong>Namangan viloyati KENGASHI</strong>":
window.location.href.indexOf('sirdaryo.vatanparvar.uz')!==-1?"O’ZBEKISTON RESPUBLIKASI <br/> MUDOFAASIGA KO’MAKLASHUVCHI <br/> “VATANPARVAR” TASHKILOTI <br/> <strong>Sirdaryo viloyati KENGASHI</strong>":
"O’ZBEKISTON RESPUBLIKASI <br/> MUDOFAASIGA KO’MAKLASHUVCHI <br/> “VATANPARVAR” TASHKILOTI <br/> <strong>Andijon viloyati KENGASHI</strong>";

export const regionA_nameUz=window.location.href.indexOf('andijon.vatanparvar.uz')!==-1?"Andijon viloyati":
window.location.href.indexOf('buxoro.vatanparvar.uz')!==-1?"Buxoro viloyati":
window.location.href.indexOf('fargona.vatanparvar.uz')!==-1?"Fargona viloyati":
window.location.href.indexOf('qoraqalpoq.vatanparvar.uz')!==-1?"Qoraqalpog'ston Respublikasi":
window.location.href.indexOf('qashqadaryo.vatanparvar.uz')!==-1?"Qashqadaryo viloyati":
window.location.href.indexOf('xorazm.vatanparvar.uz')!==-1?"Xorazm viloyati":
window.location.href.indexOf('samarqand.vatanparvar.uz')!==-1?"Samarqand viloyati":
window.location.href.indexOf('surxondaryo.vatanparvar.uz')!==-1?"Surxondaryo viloyati":
window.location.href.indexOf('toshkent.vatanparvar.uz')!==-1?"Toshkent shahri":
window.location.href.indexOf('toshvil.vatanparvar.uz')!==-1?"Toshkent viloyati":
window.location.href.indexOf('navoiy.vatanparvar.uz')!==-1?"Navoiy viloyati":
window.location.href.indexOf('jizzax.vatanparvar.uz')!==-1?"Jizzax viloyati":
window.location.href.indexOf('namangan.vatanparvar.uz')!==-1?"Namangan viloyati":
window.location.href.indexOf('sirdaryo.vatanparvar.uz')!==-1?"Sirdaryo viloyati":
"Andijon viloyati";

export const rekvizit=window.location.href.indexOf('andijon.vatanparvar.uz')!==-1?"Andijon":
window.location.href.indexOf('buxoro.vatanparvar.uz')!==-1?"Buxoro":
window.location.href.indexOf('fargona.vatanparvar.uz')!==-1?"Fargona":
window.location.href.indexOf('qoraqalpoq.vatanparvar.uz')!==-1?"QQR":
window.location.href.indexOf('qashqadaryo.vatanparvar.uz')!==-1?"Qashqadaryo":
window.location.href.indexOf('xorazm.vatanparvar.uz')!==-1?"Xorazm":
window.location.href.indexOf('samarqand.vatanparvar.uz')!==-1?"Samarqand":
window.location.href.indexOf('surxondaryo.vatanparvar.uz')!==-1?"Surxondaryo":
window.location.href.indexOf('toshkent.vatanparvar.uz')!==-1?"Toshkent":
window.location.href.indexOf('toshvil.vatanparvar.uz')!==-1?"Toshvil":
window.location.href.indexOf('navoiy.vatanparvar.uz')!==-1?"Navoiy":
window.location.href.indexOf('jizzax.vatanparvar.uz')!==-1?"Jizzax":
window.location.href.indexOf('namangan.vatanparvar.uz')!==-1?"Namangan":
window.location.href.indexOf('sirdaryo.vatanparvar.uz')!==-1?"Sirdaryo":
"Andijon";



export const region_nameRu=window.location.href.indexOf('andijon.vatanparvar.uz')!==-1?"<strong>АндижанскИЙ ОБЛАСТНОЙ СОВЕТ </strong><br/> ОРГАНИЗАЦИИ СОДЕЙСТВИЯ <br/> ОБОРОНЕ РЕСПУБЛИКИ <br/> УЗБЕКИСТАН «ВАТАНПАРВАР»":
window.location.href.indexOf('buxoro.vatanparvar.uz')!==-1?"<strong>БУХАРСКИЙ ОБЛАСТНОЙ СОВЕТ </strong><br/> ОРГАНИЗАЦИИ СОДЕЙСТВИЯ <br/> ОБОРОНЕ РЕСПУБЛИКИ <br/> УЗБЕКИСТАН «ВАТАНПАРВАР»":
window.location.href.indexOf('fargona.vatanparvar.uz')!==-1?"<strong>ФерганскИЙ ОБЛАСТНОЙ СОВЕТ </strong><br/> ОРГАНИЗАЦИИ СОДЕЙСТВИЯ <br/> ОБОРОНЕ РЕСПУБЛИКИ <br/> УЗБЕКИСТАН «ВАТАНПАРВАР»":
window.location.href.indexOf('qoraqalpoq.vatanparvar.uz')!==-1?"<strong>СОВЕТ РЕСПУБЛИКИ КАРАКАЛПАКСТАН </strong><br/> ОРГАНИЗАЦИИ СОДЕЙСТВИЯ <br/> ОБОРОНЕ РЕСПУБЛИКИ <br/> УЗБЕКИСТАН «ВАТАНПАРВАР»":
window.location.href.indexOf('qashqadaryo.vatanparvar.uz')!==-1?"<strong>КашкадарьинскИЙ ОБЛАСТНОЙ СОВЕТ </strong><br/> ОРГАНИЗАЦИИ СОДЕЙСТВИЯ <br/> ОБОРОНЕ РЕСПУБЛИКИ <br/> УЗБЕКИСТАН «ВАТАНПАРВАР»":
window.location.href.indexOf('xorazm.vatanparvar.uz')!==-1?"<strong>ХорезмскИЙ ОБЛАСТНОЙ СОВЕТ </strong><br/> ОРГАНИЗАЦИИ СОДЕЙСТВИЯ <br/> ОБОРОНЕ РЕСПУБЛИКИ <br/> УЗБЕКИСТАН «ВАТАНПАРВАР»":
window.location.href.indexOf('samarqand.vatanparvar.uz')!==-1?"<strong>СамаркандскИЙ ОБЛАСТНОЙ СОВЕТ </strong><br/> ОРГАНИЗАЦИИ СОДЕЙСТВИЯ <br/> ОБОРОНЕ РЕСПУБЛИКИ <br/> УЗБЕКИСТАН «ВАТАНПАРВАР»":
window.location.href.indexOf('surxondaryo.vatanparvar.uz')!==-1?"<strong>СурхандарьинскИЙ ОБЛАСТНОЙ СОВЕТ </strong><br/> ОРГАНИЗАЦИИ СОДЕЙСТВИЯ <br/> ОБОРОНЕ РЕСПУБЛИКИ <br/> УЗБЕКИСТАН «ВАТАНПАРВАР»":
window.location.href.indexOf('toshkent.vatanparvar.uz')!==-1?"<strong>ТАШКЕНТСКИЙ ГОРОДСКОЙ СОВЕТ </strong><br/> ОРГАНИЗАЦИИ СОДЕЙСТВИЯ <br/> ОБОРОНЕ РЕСПУБЛИКИ <br/> УЗБЕКИСТАН «ВАТАНПАРВАР»":
window.location.href.indexOf('toshvil.vatanparvar.uz')!==-1?"<strong>ТашкентскИЙ ОБЛАСТНОЙ СОВЕТ </strong><br/> ОРГАНИЗАЦИИ СОДЕЙСТВИЯ <br/> ОБОРОНЕ РЕСПУБЛИКИ <br/> УЗБЕКИСТАН «ВАТАНПАРВАР»":
window.location.href.indexOf('navoiy.vatanparvar.uz')!==-1?"<strong>НавоийскИЙ ОБЛАСТНОЙ СОВЕТ </strong><br/> ОРГАНИЗАЦИИ СОДЕЙСТВИЯ <br/> ОБОРОНЕ РЕСПУБЛИКИ <br/> УЗБЕКИСТАН «ВАТАНПАРВАР»":
window.location.href.indexOf('jizzax.vatanparvar.uz')!==-1?"<strong>ДжизакскИЙ ОБЛАСТНОЙ СОВЕТ </strong><br/> ОРГАНИЗАЦИИ СОДЕЙСТВИЯ <br/> ОБОРОНЕ РЕСПУБЛИКИ <br/> УЗБЕКИСТАН «ВАТАНПАРВАР»":
window.location.href.indexOf('namangan.vatanparvar.uz')!==-1?"<strong>НаманганскИЙ ОБЛАСТНОЙ СОВЕТ </strong><br/> ОРГАНИЗАЦИИ СОДЕЙСТВИЯ <br/> ОБОРОНЕ РЕСПУБЛИКИ <br/> УЗБЕКИСТАН «ВАТАНПАРВАР»":
window.location.href.indexOf('sirdaryo.vatanparvar.uz')!==-1?"<strong>СырдарьинскИЙ ОБЛАСТНОЙ СОВЕТ </strong><br/> ОРГАНИЗАЦИИ СОДЕЙСТВИЯ <br/> ОБОРОНЕ РЕСПУБЛИКИ <br/> УЗБЕКИСТАН «ВАТАНПАРВАР»":
"<strong>АндижанскИЙ ОБЛАСТНОЙ СОВЕТ </strong><br/> ОРГАНИЗАЦИИ СОДЕЙСТВИЯ <br/> ОБОРОНЕ РЕСПУБЛИКИ <br/> УЗБЕКИСТАН «ВАТАНПАРВАР»";

export const regionA_nameRu=window.location.href.indexOf('andijon.vatanparvar.uz')!==-1?"Андижанская область":
window.location.href.indexOf('buxoro.vatanparvar.uz')!==-1?"Бухарская область":
window.location.href.indexOf('fargona.vatanparvar.uz')!==-1?"Фергонская область":
window.location.href.indexOf('qoraqalpoq.vatanparvar.uz')!==-1?"Республика Каракалпакстан":
window.location.href.indexOf('qashqadaryo.vatanparvar.uz')!==-1?"Кашкадарьинская область":
window.location.href.indexOf('xorazm.vatanparvar.uz')!==-1?"Хорезмская область":
window.location.href.indexOf('samarqand.vatanparvar.uz')!==-1?"Самаркандская область":
window.location.href.indexOf('surxondaryo.vatanparvar.uz')!==-1?"Сурхандарьинская область":
window.location.href.indexOf('toshkent.vatanparvar.uz')!==-1?"город Ташкент":
window.location.href.indexOf('toshvil.vatanparvar.uz')!==-1?"Ташкентская область":
window.location.href.indexOf('navoiy.vatanparvar.uz')!==-1?"Навоийская область":
window.location.href.indexOf('jizzax.vatanparvar.uz')!==-1?"Джизакская область":
window.location.href.indexOf('namangan.vatanparvar.uz')!==-1?"Наманганская область":
window.location.href.indexOf('sirdaryo.vatanparvar.uz')!==-1?"Сырдарьинская область":
"Андижанская область ";









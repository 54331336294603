import React, { useState } from 'react';

import "./assets/navbar.scss";
import { RiMenu2Line } from "react-icons/ri";
import { GrClose } from "react-icons/gr";
import { LanguageSwitcher } from '../ui/LanguageSwitcher';
import { NavLink } from "react-router-dom";
// @ts-ignore
import { SettingsModal } from '@n3/react-vision-panel';
import { BsFillEyeFill } from "react-icons/bs";
import { useTranslation } from 'react-i18next';
import { region_nameUz } from '../../host';

export function AppNavbar() {
    const [open, setOpen] = useState(false);
    const [showVisionPanel, setShowVisionPanel] = useState(false);
    const [isActivemarkaziy, setisActivemarkaziy] = useState(false)
    const [isActiverahbariyat, setisActiverahbariyat] = useState(false)
    const [isActivemarkaziy_aparat, setisActivemarkaziy_aparat] = useState(false)
    const [isActivetassarufidagi, setisActivetassarufidagi] = useState(false)
    const [isActivevacancy, setisActivevacancy] = useState(false)
    const [isActivesports, setisActivesports] = useState(false)
    const [isActivejobs, setisActivejobs] = useState(false)
    const [isActivekorrupsiya, setisActivekorrupsiya] = useState(false)
    const [isActivebarcha_yangiliklar, setisActivebarcha_yangiliklar] = useState(false)
    const [isActivefotogalereya, setisActivefotogalereya] = useState(false)
    const [isActivekantaktlar, setisActivekantaktlar] = useState(false)
    const [isActivexarita, setisActivexarita] = useState(false)
    const [isActiveonlayn, setisActiveonlayn] = useState(false)
    const [isActiveh, setisActiveh] = useState(false)
    const [isovv, setisovv] = useState(false)
    const [isActivek, setisActivek] = useState(false)
    const [isbiz_haqimizda, setisbiz_haqimizda] = useState(false)
    const [isaxborot_xizmati, setisaxborot_xizmati] = useState(false)
    const [ismurojat_qilish, setismurojat_qilish] = useState(false)
    const [yoshlar_uchun, setyoshlar_uchun] = useState(false)
    const [yoshlar_hujjat, setyoshlar_hujjat] = useState(false)
    const [yoshlar_qahramon, setyoshlar_qahramon] = useState(false)
    const [yoshlar_news, setyoshlar_news] = useState(false)
    const [call_center, setcall_center] = useState(false)
    const [contact, setcontact] = useState(false)
 
 

const setFalse=()=>{
setisActivemarkaziy(false)
setisActivejobs(false)
setisActivesports(false)
setisActiverahbariyat(false)
setisActivemarkaziy_aparat(false)
setisActivetassarufidagi(false)
setisActivebarcha_yangiliklar(false)
setisActivefotogalereya(false)
setisActivekantaktlar(false)
setisActivexarita(false)
setisActiveonlayn(false)
setisActiveh(false)
setisActivek(false)
setisbiz_haqimizda(false)
setisaxborot_xizmati(false)
setismurojat_qilish(false)
setyoshlar_uchun(false)
setismurojat_qilish(false)
setyoshlar_hujjat(false)
setisovv(false)
setisActivevacancy(false)
setyoshlar_qahramon(false)
setyoshlar_news(false)
setcall_center(false)
setcontact(false)
setisActivekorrupsiya(false)
}

  
    const { t } = useTranslation();
    const closeNavbar = () => {
        setOpen(false)
    }
    return (
        <nav className="app_nav">
  {/* <div className="lenta">
    <p className='lenta_text_1'><span>2024-yil 14-yanvar kuni O‘zbekiston Respublikasi Qurolli Kuchlari tashkil topganiga 32 yil to‘ladi</span> 
    <span>14 января 2024 года исполняется 32 года со дня создания Вооружённых Сил Республики Узбекистан</span></p>
   
    
   </div> */}
            <div className="container">
                <div className="app_nav-info" onClick={()=>{setFalse()}}>
                    <NavLink to={t("check")?"/":"/ru"} className="app_nav-logo">
                        <img src="https://vatanparvar.uz/Files/images/logo.gif" alt="logo" />
                    </NavLink>
                    <p style={{textTransform:'uppercase'}} dangerouslySetInnerHTML={{ __html: t("check")?region_nameUz:region_nameUz }}>
                    </p>
                </div>
                <div className={`app_nav-links ${open && "open"}`}>

                    <ul className="app_nav-list">
                        <li className="app_nav-list_item with_sublist">

                            <p  className={isbiz_haqimizda?"active":''}><a className="app_nav-list_link">{t('biz_haqimizda')}</a> </p>
                            <ul className="app_nav-sub_list"> 
                            <div className='chiz1'></div>

                                <div className='chiz'></div>
                                <li className="app_nav-sub_list-item" onClick={closeNavbar}>
                                    <NavLink onClick={()=>{setFalse();setisbiz_haqimizda(true);setisActivemarkaziy(true) }} className={isActivemarkaziy?"active":''}  to={t("check")?"/markaziy-kengash":"/markaziy-kengash/ru"}><a className="app_nav-sub_list-link" href="../home/Markaziy">{t('markaziy_kengash')}</a></NavLink>
                                </li>
                                <li className="app_nav-sub_list-item" onClick={closeNavbar}>
                                    <NavLink onClick={()=>{setFalse();setisbiz_haqimizda(true);setisActiverahbariyat(true) }} className={isActiverahbariyat?"active":''}  to={t("check")?"/rahbariyat":"/rahbariyat/ru"}> <a className="app_nav-sub_list-link">{t('rahbariyat')}</a></NavLink>
                                </li>
                                <li className="app_nav-sub_list-item" onClick={closeNavbar}>
                                    <NavLink onClick={()=>{setFalse();setisbiz_haqimizda(true);setisActivemarkaziy_aparat(true) }} className={isActivemarkaziy_aparat?"active":''}  to={t("check")?"/markaziy-aparat":"/markaziy-aparat/ru"} > <a className="app_nav-sub_list-link">{t('markaziy_aparat')}</a></NavLink>
                                </li>
                                 <li className="app_nav-sub_list-item" onClick={closeNavbar}>
                                    <NavLink onClick={()=>{setFalse();setisbiz_haqimizda(true);setisActivekorrupsiya(true) }} className={isActivekorrupsiya?"active":''}  to={t('check')?"/korrupsiya":"/korrupsiya/ru"}>  <a className="app_nav-sub_list-link" href="#" dangerouslySetInnerHTML={{ __html: t("korrupsiya") }}></a></NavLink>
                                </li>
                                <li className="app_nav-sub_list-item" onClick={closeNavbar}>
                                    <NavLink onClick={()=>{setFalse();setisbiz_haqimizda(true);setisActivevacancy(true) }} className={isActivevacancy?"active":''}  to={t('check')?"/vacancy":"/vacancy/ru"}>  <a className="app_nav-sub_list-link" href="#" dangerouslySetInnerHTML={{ __html: t("vacancy") }}></a></NavLink>
                                </li>
                            </ul>
                        </li>
                        <li className="app_nav-list_item with_sublist">
                            <p  className={isaxborot_xizmati?"active":''}><a className="app_nav-list_link">{t('axborot_xizmati')}</a></p>
                            <ul className="app_nav-sub_list"> 
                            <div className='chiz1'></div>

<div className='chiz'></div>
                              
                                <li className="app_nav-sub_list-item" onClick={closeNavbar}>
                                    <NavLink onClick={()=>{setFalse();setisaxborot_xizmati(true);setisActivebarcha_yangiliklar(true) }} className={isActivebarcha_yangiliklar?"active":''}  to={t("check")?"/allnews":"/allnews/ru"}><a className="app_nav-sub_list-link">{t('barcha_yangiliklar')}</a></NavLink>
                                </li>
                                <li className="app_nav-sub_list-item" onClick={closeNavbar}>
                                    <NavLink onClick={()=>{setFalse();setisaxborot_xizmati(true);setisActivefotogalereya(true) }} className={isActivefotogalereya?"active":''}  to={t("check")?"/fotogalareya":"/fotogalareya/ru"}><a className="app_nav-sub_list-link">{t('fotogalereya')}</a></NavLink>
                                </li>
                                <li className="app_nav-sub_list-item" onClick={closeNavbar}>
                                    <p  onClick={()=>{setFalse();setisaxborot_xizmati(true);setisovv(true) }} className={isovv?"active":''}><a className="app_nav-sub_list-link" href="https://www.youtube.com/channel/UCI41Xe2P3GPUz5YK-TzEJZg" target="_blank">{t('ovv_biz_haqimizda')}</a></p>
                                </li>
                            </ul>
                        </li>
                      
                         <li className="app_nav-list_item" onClick={closeNavbar}>
                                    <NavLink onClick={()=>{setFalse();setisActivetassarufidagi(true) }} className={isActivetassarufidagi?"active":''}  to={t("check")?"/filiallar":"/filiallar/ru"}><a className="app_nav-list_link" dangerouslySetInnerHTML={{ __html: t("tassarufidagi_tashkilotlar") }}></a></NavLink>
                                </li>
                                <li className="app_nav-list_item with_sublist">
                            <p  className={yoshlar_uchun?"active":''}><a className="app_nav-list_link" href="#">{t('yoshlar uchun')}</a></p>
                            <ul className="app_nav-sub_list"> 
                            <div className='chiz1'></div>

<div className='chiz'></div>
                               
                                <li className="app_nav-sub_list-item" onClick={closeNavbar}>
                                    <NavLink onClick={()=>{setFalse();setyoshlar_uchun(true);setyoshlar_hujjat(true) }} className={yoshlar_hujjat?"active":''}  to={t('check')?"/yoshlar-hujjatlar":"/yoshlar-hujjatlar/ru"}>
                                        <a href="#" className="app_nav-sub_list-link" dangerouslySetInnerHTML={{__html:t('Yoshlarga oid davlat siyosati bo‘yicha hujjatlar')}}></a>
                                        </NavLink>
                                </li>
                                <li className="app_nav-sub_list-item" onClick={closeNavbar}>
                                    <NavLink onClick={()=>{setFalse();setyoshlar_uchun(true);setyoshlar_qahramon(true) }} className={yoshlar_qahramon?"active":''}  to={t('check')?"/yosh-qahramonlar":"/yosh-qahramonlar/ru"}>
                                        <a className="app_nav-sub_list-link" href="#">{t('Bugungi yosh qahramonlarimiz')}</a>
                                        </NavLink>
                                </li>
                                <li className="app_nav-sub_list-item" onClick={closeNavbar}>
                                    <NavLink onClick={()=>{setFalse();setyoshlar_uchun(true);setyoshlar_news(true) }} className={yoshlar_news?"active":''}  to={t('check')?"/yoshlar-uchun-yangiliklar":"/yoshlar-uchun-yangiliklar/ru"}>
                                        <a className="app_nav-sub_list-link" href="#">{t('Yangiliklar')}</a>
                                        </NavLink>
                                </li>
                                <li className="app_nav-sub_list-item" onClick={closeNavbar}>
                                    <NavLink onClick={()=>{setFalse();setyoshlar_uchun(true);setcall_center(true) }} className={call_center?"active":''}  to={t('check')?"/call-center":"/call-center/ru"}><a className="app_nav-sub_list-link" href="#">Call Center</a></NavLink>
                                </li>
                            </ul>
                            </li>
                                <li className="app_nav-list_item with_sublist">
                            <p  className={contact?"active":''}><a className="app_nav-list_link" href="#">{t("Yo'nalishlar")}</a></p>
                            <ul className="app_nav-sub_list"> 
                            <div className='chiz1'></div>

<div className='chiz'></div>
                                {/* <li className="app_nav-sub_list-item">
                                    <a className="app_nav-sub_list-link" href="#">E'LONLAR</a>
                                </li> */}
                               <li className="app_nav-sub_list-item" onClick={closeNavbar}>
                            <NavLink onClick={()=>{setFalse();setisActivesports(true);setcall_center(true); }} className={isActivesports?"active":''}  to={t('check')?"/sportlar":"/sportlar/ru"}><a className="app_nav-sub_list-link" href="#">{t('sports')}</a></NavLink>
                        </li>
                        <li className="app_nav-sub_list-item" onClick={closeNavbar}>
                            <NavLink onClick={()=>{setFalse();setisActivejobs(true);setcall_center(true); }} className={isActivejobs?"active":''}  to={t('check')?"/kasblar":"/kasblar/ru"}><a className="app_nav-sub_list-link" href="#">{t('jobs')}</a></NavLink>
                        </li>
                      
                            </ul>
                            </li>
                           
                       
                        <li className="app_nav-list_item with_sublist">
                            <p  className={ismurojat_qilish?"active":''}><a className="app_nav-list_link">{t("Bog'lanish")}</a></p>
                            <ul className="app_nav-sub_list"> 
                            <div className='chiz1'></div>

<div className='chiz'></div>
<li className="app_nav-sub_list-item" onClick={closeNavbar}>
                            <NavLink onClick={()=>{setFalse();setisActivexarita(true);setismurojat_qilish(true); }} className={isActivexarita?"active":''}  to={t('check')?"/xarita/0":"/xarita/ru/0"}><a className="app_nav-sub_list-link">{t('xarita')}</a></NavLink>
                        </li>
                        <li className="app_nav-sub_list-item" onClick={closeNavbar}>
                            <NavLink onClick={()=>{setFalse();setisActivekantaktlar(true);setismurojat_qilish(true); }} className={isActivekantaktlar?"active":''}  to={t('check')?"/kontaktlar":"/kontaktlar/ru"}><a className="app_nav-sub_list-link" href="#">{t('kantaktlar')}</a></NavLink>
                        </li>
                                <li className="app_nav-sub_list-item" onClick={closeNavbar}>
                                    <NavLink onClick={()=>{setFalse();setismurojat_qilish(true);setisActiveonlayn(true) }} className={isActiveonlayn?"active":''}  to={t("check")?"/onlayn-murojat":"/onlayn-murojat/ru"}><a className="app_nav-sub_list-link">{t('onlayn_murojat')}</a></NavLink>
                                </li>
                                <li className="app_nav-sub_list-item" onClick={closeNavbar}>
                                    <NavLink onClick={()=>{setFalse();setismurojat_qilish(true);setisActiveh(true) }} className={isActiveh?"active":''}  to={t("check")?"/haydovchilik-maktabiga-yozilish":"/haydovchilik-maktabiga-yozilish/ru"}><a className="app_nav-sub_list-link">{t('h_maktabga')}</a></NavLink>
                                </li>
                                <li className="app_nav-sub_list-item" onClick={closeNavbar}>
                                    <NavLink onClick={()=>{setFalse();setismurojat_qilish(true);setisActivek(true) }} className={isActivek?"active":''}  to={t("check")?"/kasb-hunar-maktabiga-yozilish":"/kasb-hunar-maktabiga-yozilish/ru"}> <a className="app_nav-sub_list-link" dangerouslySetInnerHTML={{ __html: t("k_maktabga") }}></a></NavLink>
                                </li>
                            </ul>
                        </li>
                       
                        <div className="menu_lang-switcher">
                            <LanguageSwitcher />
                            <BsFillEyeFill style={{ marginLeft: "10px", fontSize: 20, color:"#133165", lineHeight: "100%" }} onClick={() => setShowVisionPanel(true)} />
                        </div>
                    </ul>
                    <div className="app_nav-close_btn" onClick={() => setOpen(false)}>
                        <GrClose size="2.5em" />
                    </div>
                </div>
                <div className="app_lang-switcher">
                    <LanguageSwitcher />
                    <BsFillEyeFill style={{ marginLeft: "10px", fontSize: 20, color:"#133165", lineHeight: "100%" }} onClick={() => setShowVisionPanel(true)} />
                </div>
                <div className="app_nav-burger_btn" onClick={() => setOpen(true)}>
                    <RiMenu2Line size="2.5em" />
                </div>
            </div>
           
            <SettingsModal show={showVisionPanel} onHide={(e:any) => { console.log(e); setShowVisionPanel(false)}} />
           
           
        </nav>
    );
}



import { useEffect, useState } from 'react';
import { Image } from 'antd';
import { Swiper, SwiperSlide } from 'swiper/react';
import "./assets/OpenNews.scss";
import { Link, Navigate, useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { api, url, urlSJ } from '../host';
import { useStore } from '../store/Store';
import http from '../components/ui/Services';




function OpenSport() {
    const { t } = useTranslation();
    const params = useParams();
    const { id } = params;
    const sports=useStore(state=>state.sports)
    const setsports=useStore(state=>state.setsports)
    const [idT, setidT] = useState<any>(Number(id))
    const [error, seterror]=useState(false)

    useEffect(()=>{
      if(sports===null){
          http.get<any>(`/GetApi/GetSportsApi/`).then((res) => {
            setsports(res.data);
            if(!res.data[idT]){
              seterror(true)
            }  })
          .catch(e => console.log(e))
      }
  
  },[])

     return (

        <div className="open-sports" style={{padding:'50px 18%'}}>
        
            {
            sports===null?<Navigate to="/sportlar" />:
            sports!==null? <div className="container" >
                <Row >
<Col lg={17} md={24} sm={24}>

<div className="open-sports-title">
                    {Object.keys(sports[idT]).length !== 0 &&
                      <h5>{t('lang') === 'uz' ? sports[idT].titleUz : sports[idT].titleRu}</h5>
      
                    }
                </div>
              
                <Image.PreviewGroup>
            <Swiper
              slidesPerView={1}
              spaceBetween={30}
              autoplay={{
                delay: 4000,
                disableOnInteraction: false,
              }}
              loop={true}
              navigation={true}
              className="mySwiper"
            >
              <SwiperSlide>
              <div
                        className="swiper-slide_body"
                       style={{
                     
                          display:'flex',
                          alignItems:'center',
                          justifyContent:'center',
                    
                          
                       }}
                      >
                        <Image className='red' style={{height:'auto', width:"100%" }} src={`${urlSJ}/Files/Sports/${sports[idT].titleImage}`}/>  
                      </div>
                      </SwiperSlide>
                  
                  {sports[idT].sportsImage && sports[idT].sportsImage.length!==0?sports[idT].sportsImage.map((item: any, index: any)=>{
                      return(<SwiperSlide><div
                        className="swiper-slide_body"
                        style={{
                            display:'flex',
                            alignItems:'center',
                            justifyContent:'center',
                            }}
                      >
                             <Image className='red' style={{height:'auto', width:"100%"}} src={`${urlSJ}/Files/Sports/${item.imageUrl}`}/>  
                      </div>
                       </SwiperSlide>)

                  }):''} </Swiper>

                <div className="open-sports-text">
                   
                        <p className='salomText' style={{textAlign:'justify', lineHeight:"2", fontSize:'18px', textIndent:'50px'}} dangerouslySetInnerHTML={{__html: t('lang') === 'uz' ? sports[idT].mainTextUz : sports[idT].mainTextRu}}/>
                   
                </div>
            </Image.PreviewGroup>
    </Col>
    <Col lg={7} md={0} sm={0} className="opensportitemCol">
   <ul className="opensportitem">
       <h4>{t("sports")}</h4>
       
       {sports.map((item:any, key:any)=>{
           return( <li onClick={()=>{setidT(key)}}>
           <div  className="news_list-item1" key={key}>
            <div className='titles'>             
            <p className='tabTitle' style={{textTransform:'uppercase'}}>{t('lang') === 'uz' ? item.titleUz : item.titleRu}</p>
            </div></div>
            </li>)
       })}
  
   </ul>
        </Col>

                </Row>
      
<br/>
<br/>

            </div>:<></>}
          
        </div>
    )
}

export default OpenSport;
import React, { useEffect, useState } from 'react';
import { PageTitle } from '../../ui/PageTitle';
import "./assets/hamkorlik.scss";
import "./assets/header-slider.scss"

import { useTranslation } from 'react-i18next';
import style from './assets/Coorparation.module.css'
import { Image } from 'antd';
import { Swiper, SwiperSlide } from 'swiper/react';
import card1 from '../leaders/assets/user.png'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link, Navigate } from 'react-router-dom';
import { api, region, regionI, urlPer } from '../../../host';
import http from '../../ui/Services';
import { Container } from 'react-bootstrap';

const responsive1 = {
  superLargeDesktop: {

    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop1: {
    breakpoint: { max: 3000, min: 1500 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 1500, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 664 },
    items: 2,
  },
  mobile1: {
    breakpoint: { max: 664, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const Cooperation=()=> {
  const {t} = useTranslation();
  const [prava, setprava]=useState<any>([])
  const [kasb, setkasb]=useState<any>([])
  const [sport, setsport]=useState<any>([])
  const [loader, setLoader] = useState(true)
  const [error, seterror] = useState(false)
  const [data, setdata] = useState<any>(null)
  const [titleUz, setTitleUz] = useState<any>(null)
  const [titleRu, setTitleRu] = useState<any>(null)
  const loaderT=()=>{
      setTimeout(()=>{
          setLoader(false)
      }, 0)
  }

  useEffect(()=>{
    var id=window.location.href
var idT=Number(id.split('/')[id.split('/').length-1])
    if(!(/^[\wа-я]*$/.test(id.split('/')[id.split('/').length-1]))){
       window.location.href=api+'/error'

    }
    http.get<any>(`GetApi/GetOrganizationProfessionSections/${idT}/`).then(res=>{
      http.get<any>(`GetApi/OrganizationProfessionSectionsInfo`).then(res1=>{
        var a=res.data
        a.forEach((item:any)=>{
var img=res1.data.filter((x:any)=>x.categoryId==item.id)
if(img.length!==0){
  item.image=img[0].image
}
        })
        setkasb(a)
        loaderT()
      })
    }).catch(err=>{
      seterror(true)
    })
    http.get<any>(`GetApi/GetOrganizationSportSections/${idT}/`).then(res=>{
      http.get<any>(`GetApi/OrganizationSportSectionsInfo`).then(res1=>{
        var a=res.data
        a.forEach((item:any)=>{
var img=res1.data.filter((x:any)=>x.categoryId==item.id)
if(img.length!==0){
  item.image=img[0].image
}
        })
        setsport(a)
        loaderT()
      })
     })
    http.get<any>(`GetApi/GetOrganizationCategories/${idT}/`).then(res=>{
      http.get<any>(`GetApi/OrganizationCategoriesInfo`).then(res1=>{
        var a=res.data
        a.forEach((item:any)=>{
var img=res1.data.filter((x:any)=>x.categoryId==item.id)
if(img.length!==0){
  item.image=img[0].image
}
        })
        setprava(a)
      })
     
      
    })
    http.get<any>(`/GetApi/GetBranchContactsByRegion/${regionI}/`)
    .then((res) => {
      http.get<any>(`/GetApi/GetBranchesInfoByRegion/${regionI}/`).then((res1) => {
        var a=[]
        a.push(res.data.filter((data1: any) => data1.branchId === idT))
      var image=res1.data.filter((x:any)=>x.organizationId===idT)
        var config=a[0][0]
        if(image.lenght!==0){
          config.branchImage=urlPer+"/"+image[0].branchImage
          config.branchImageDescriptionRu=image[0].branchImageDescriptionRu
          config.branchImageDescriptionUz=image[0].branchImageDescriptionUz
        }    
        var textUz=config.branchImageDescriptionUz  
            var textRu=config.branchImageDescriptionRu
  
            var Uz=""  
            var Ru=""  
            if(textUz!==null){
                textUz.split("</p>\r\n\r\n<p>&nbsp;</p>\r\n\r\n<p>").map((item:any)=>{
                    Uz+=item+"<br/><br/>"
  
                                })
                                textUz=""
                                Uz.split("</p>\r\n\r\n<p>").map((item:any)=>{
                
                                    textUz+=item+"<br/>"
                                                })
                                                config.branchImageDescriptionUz=textUz
            }
            if(textRu!==null){
  
                textRu.split("</p>\r\n\r\n<p>&nbsp;</p>\r\n\r\n<p>").map((item:any)=>{
                    Ru+=item+"<br/><br/>"
                                })
                                
                                textRu=""
  
                Ru.split("</p>\r\n\r\n<p>").map((item:any)=>{
                    textRu+=item+"<br/>"
                                })
  
                                                                
                                                                config.branchImageDescriptionRu=textRu
            }
           
            setdata(config); 
          setTitleRu(config.branchNameRu)
          setTitleUz(config.branchName)

      })
     
        })
  
      
    
  }, [])
  return (
    <Image.PreviewGroup>
      {  error?<Navigate to="/error" />:
      <>
      <div className="hamkorlik">
      {loader?<div className="loaderG">
      <div className="befG">
  <img src="https://vatanparvar.uz/Files/images/logo.gif" alt="..."/>
  </div>
  
  </div>
  :''}
          <div className="container">
              <PageTitle title={t('lang')=="uz"?titleUz:titleRu}/>
              <div className="containerr">
                  <div className="hamkorlik-info">
                  <Swiper
              slidesPerView={1}
              spaceBetween={30}
              autoplay={{
                delay: 4000,
                disableOnInteraction: false,
              }}
              loop={true}
              navigation={true}
              className="mySwiper"
            >
              <SwiperSlide>
              <div
                        className="swiper-slide_body"
                       style={{
                     
                          display:'flex',
                          alignItems:'center',
                          justifyContent:'center',
                          
                          
                       }}
                      >
                        <Image className='red' style={{width:"100%" }} src={data!==null?data.branchImage:''}/>  
                      </div>
                      </SwiperSlide></Swiper>
                      <div className="open-news-text">
                    {data !== null?
                        <p>
                            <p className='salomText' style={{textAlign:'justify', lineHeight:"2", fontSize:'18px', textIndent:'50px'}} dangerouslySetInnerHTML={{__html: t('lang') === 'uz' ? data.branchImageDescriptionUz : data.branchImageDescriptionRu}}>

                            </p>
                        </p>:''
                    }
                </div>
                 </div>
       

                
              </div>
             
          </div>
     
      </div>
      <Container>
      <div style={{padding:'40px'}}>

{sport.length!==0?<>      <PageTitle title={t("Sport to'garaklari")}/>
<Carousel
            
            swipeable={true}     
   showDots={true}
   responsive={responsive1}
   ssr={true} // means to render carousel on server-side.
   infinite={true}
   autoPlay={true}
   autoPlaySpeed={2000}
   
   containerClass="carousel-container"
   dotListClass="custom-dot-list-style"
   itemClass="carousel-item-padding-40-px"
   >
   {sport.map((item:any)=>{
     return(<div  style={{ padding:'40px 10px'}}>
     <div className={style.card2}>
       {/* <div className={style.lenta}>Chegirma !!!</div> */}
   <div className={style.img1}>
   <img alt="..." src={item.image!==null?item.image:card1}/>
   
   
   </div>
   <p className={style.narx}><b>{t("Bepul")}</b></p>
   <h1>{t("check")?item.nameUz!==null?item.nameUz:item.nameRu:item.nameRu!==null?item.nameRu:item.nameUz}</h1>
   
   <ul>
     {item.sportSections!==null && item.sportSections.length!==0?item.sportSections.map((item1:any)=>{
return( <li>{t("check")?item1.nameUz!==null?item1.nameUz:item1.nameRu:item1.nameRu!==null?item1.nameRu:item1.nameUz}</li>)
     }):''}
   
   </ul>
   <Link to={t("check")?"/onlayn-murojat":"/onlayn-murojat/ru"} className={style.but}>Ariza yuborish</Link>
     </div>
   </div>)
   })}
   
   
   </Carousel></>:''}

<br/><br/><br/><br/>
              <br/>

              {prava.length!==0?<> <PageTitle title={t("Haydovchilik guvohnomasiga tayyorlash")}/>
      <Carousel
            
            swipeable={true}     
   showDots={true}
   responsive={responsive1}
   ssr={true} // means to render carousel on server-side.
   infinite={true}
   autoPlay={true}
   autoPlaySpeed={2000}
   
   containerClass="carousel-container"
   dotListClass="custom-dot-list-style"
   itemClass="carousel-item-padding-40-px"
   >
   {prava.map((item:any)=>{
     return(<div  style={{ padding:'40px 10px'}}>
     <div className={style.card1}>
       {/* <div className={style.lenta}>Chegirma !!!</div> */}
   <div className={style.img1}>
   <img alt="..." src={item.image!==null?item.image:card1}/>
   
   
   </div>
   <p className={style.narx}><b>{item.price===null?t("Bepul"):item.price+" so'm"}</b></p>
   <h1>{t("check")?item.nameUz!==null?item.nameUz:item.nameRu:item.nameRu!==null?item.nameRu:item.nameUz}</h1>
   <h3>{item.hours} soat</h3>
   
   <Link to={t("check")?"/haydovchilik-maktabiga-yozilish":"/haydovchilik-maktabiga-yozilish/ru"} className={style.but}>Ariza yuborish</Link>
     </div>
   </div>)
   })}
   
   
   </Carousel></>:''}
     
<br/><br/><br/>
<br/>
{kasb.length!==0?
<>
<PageTitle title={t("Kasbga tayyorlash")}/>
              <Carousel
          
         swipeable={true}     
showDots={true}
responsive={responsive1}
ssr={true} // means to render carousel on server-side.
infinite={true}
autoPlay={true}
autoPlaySpeed={2000}

containerClass="carousel-container"
dotListClass="custom-dot-list-style"
itemClass="carousel-item-padding-40-px"
>
{kasb.map((item:any)=>{
  return(<div  style={{ padding:'40px 10px'}}>
  <div className={style.card3}>
    {/* <div className={style.lenta}>Chegirma !!!</div> */}
<div className={style.img1}>
<img alt="..." src={item.image!==null?item.image:card1}/>


</div>
<p className={style.narx}><b>{item.price===null?t("Bepul"):item.price+" so'm"}</b></p>
<h1>{t("check")?item.nameUz!==null?item.nameUz:item.nameRu:item.nameRu!==null?item.nameRu:item.nameUz}</h1>
<h3>{item.hours} soat</h3>

<Link to={t("check")?"/kasb-hunar-maktabiga-yozilish":"/kasb-hunar-maktabiga-yozilish/ru"} className={style.but}>Ariza yuborish</Link>
  </div>
</div>)
})}


</Carousel></>:''}

<br/><br/><br/>
<br/>

                  <br/>
<br/></div>
</Container>
</>
}      </Image.PreviewGroup>
  );
}

export default  Cooperation;

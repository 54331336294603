import React, { useEffect, useState } from "react";
import { PageTitle } from "../../ui/PageTitle";

import { HiOutlineMail } from "react-icons/hi";
import { FiPhone } from "react-icons/fi";
import { FaRegCalendarCheck } from "react-icons/fa";
import "../leaders/assets/rahbariyat.scss";
import { Image } from 'antd';

import user from '../leaders/assets/user.png'
import { useTranslation } from 'react-i18next';
import http from "../../ui/Services";
import {  regionI, urlImg, urlPer } from "../../../host";
import { Link } from "react-router-dom";
import { AiOutlineRight } from "react-icons/ai";
import { MdOutlineAlternateEmail } from "react-icons/md";
//@ts-ignore
function Leaders() {
  const {t} = useTranslation();
  const [loader, setLoader] = useState(true)
  const [data, setData] = useState<any>(null)
  const loaderT=()=>{
     
  }
  useEffect(()=>{
    http.get<any>(`/GetApi/GetBranchContactsByRegion/${regionI}/`)
        
    .then((res) => {

      res.data.sort(function(a:any, b:any){
        if(a.branchName < b.branchName) { return -1; }
        if(a.branchName > b.branchName) { return 1; }
        return 0;
    })
    http.get<any>(`/GetApi/GetBranchesInfoByRegion/${regionI}/`).then((res1) => {
    var data=res.data
      for(let i=0; i<data.length; i++){
var image=res1.data.filter((x:any)=>x.organizationId==data[i].branchId)
if(image.length!==0){
  data[i].branchImage=urlPer+"/"+image[0].branchImage
  data[i].branchImageDescriptionRu=image[0].branchImageDescriptionRu
  data[i].branchImageDescriptionUz=image[0].branchImageDescriptionUz
}
    }
    setData(data); setTimeout(()=>{
      setLoader(false)
  }, 0)
    })

  })
    .catch(e => console.log(e))
  },[])
  return (
    <Image.PreviewGroup>
    <div className="rahbariyat" onLoad={()=>{loaderT()}}>
    {loader?<div className="loaderG">
    <div className="befG">
<img src="https://vatanparvar.uz/Files/images/logo.gif" alt="..."/>
</div>

</div>
:''}
      <div className="container">
      <PageTitle title={t('TASSARUFIDAGI TASHKILOTLAR')} />
        <div className="rahbariyat-info">
        
        {data!==null?data.map((item:any, index:any)=>{
          return(
            <div className="rahbariyat-list sal">
              
             <div className="content-wrapper">
             <div className="rahbariyat-list-text" style={{display:"flex", padding:'0px', alignItems:'flex-start', justifyContent:'center'}}> 
              <Image  className="img" src={item.branchImage!==null?item.branchImage:user} alt="img" />
              </div>
              <div className="rahbariyat-list-text sug">
                <p  className="rahbariyat-list-text-position" style={{marginTop:"10px"}}>
                  <b style={{lineHeight:'30px'}}>
                  {t('check')?item.branchName:item.branchNameRu}       
                </b></p>
  
                <p  className="rahbariyat-list-text-name">
                {t('check')?item.branchPresident:item.branchPresidentRu}
              
                </p>
                <div className="icons" style={{marginTop:'-10px'}}>
                <FiPhone className="icon"  size="1.5rem" color="#244BA4" cursor="pointer"/>
                  <p  style={{position:'relative', top:'-14px'}}><a href={`tel: ${item.phoneNumber}`}>{item.phoneNumber!==null?item.phoneNumber:' - '}</a></p>
                </div>
                <div className="icons">
                <HiOutlineMail className="icon iconn" size="1.5rem" color="#244BA4" cursor="pointer"/>
                  <p  style={{position:'relative', top:'-14px'}}><a>{item.mailIndex!==null?item.mailIndex:' - '}</a></p>
                </div>
              
                <div className="icons">
                <MdOutlineAlternateEmail className="icon iconn" size="1.5rem" color="#244BA4" cursor="pointer"/>
                  <p  style={{position:'relative', top:'-14px'}}><a href={`mailto: ${item.email}`}>{item.email!==null?item.email:' - '}</a></p>
                </div>
               
                <div className="web1">
<Link className="butS" to={t("check")?`/tashkilot/${item.branchId}`:`/tashkilot/ru/${item.branchId}`}>{t("FILIAL HAQIDA")} <AiOutlineRight style={{position:'relative', top:"3px"}} size="1.2rem"/></Link>
              </div>
              </div>
              </div> 
            </div>
          )
        }):''}
       
        
        </div>
      </div>
    </div>
    </Image.PreviewGroup>
  );
}

export default Leaders;

import http from '../components/ui/Services';
import {useEffect} from 'react'
import { useStore } from '../store/Store';
import './Sports.css'
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { urlSJ } from '../host';
import { PageTitle } from '../components/ui/PageTitle';
import { Link } from 'react-router-dom';
export default function Jobs() {
    const jobs=useStore(state=>state.jobs)
    const setjobs=useStore(state=>state.setjobs)
    const {t}=useTranslation()
    useEffect(()=>{
        if(jobs===null){
            http.get<any>(`/GetApi/GetJobsApi/`)
          
            .then((res) => {setjobs(res.data); })
            .catch(e => console.log(e))
        }
    
    },[])
  return (
    <div className='hujjatlar sports'>
        <div className="container">
         <PageTitle title={t('jobs')} />
        <Row style={{justifyContent:'center'}}>
            {jobs!==null?jobs.map((item:any, key:any)=>{
                return( <Col style={{padding:'10px'}} key={key} lg={8} md={12} sm={24}>
                    <Link to={`/kasblar/${key}`} className='sports_card'>
                        <div className='sports_text'>
                            <p>
                            {t('check')?item.titleUz:item.titleRu}
                            </p>
                        </div>
                        <img src={`${urlSJ}/Files/Jobs/${item.titleImage}`}/>
                    </Link>
                  </Col>)
            }):<></>}
         
        </Row>
    </div>
    </div>
  )
}

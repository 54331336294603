import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { PageTitle } from '../../ui/PageTitle'
import style from './assets/Sportsmens.module.css'
import docs from './assets/docs.png'
import http from '../../ui/Services'
import { api } from '../../../host'
export default function Sportsmens() {
    const [data, setData]=useState<any>(null)
    const [student, setStudent]=useState<any>(null)
    const [open, setOpen]=useState<boolean>(false)
    const {t}=useTranslation()
    useEffect(()=>{
        http.get('GetApi/GetSportPupils').then(res=>{
           setData(res.data)
         
        })
    },[])
  return (
    <div className="hujjatlar">
    <div className="container">
      <PageTitle title={t('Bugungi yosh qahramonlarimiz')} />
      <div className="containerr">
      <Row className={style.row}>
        {data!=null?
        data.map((item:any, key:any)=>{
          return(<Col onClick={()=>{setStudent(item); setOpen(true)}}  className={style.col} lg={8} md={12} sm={24}>
            <div className={style.card}>
              <img src={`https://vatanparvar.uz/Files/SportPupils/${item.image!=null?item.image:''}`}/>
              <div className={style.card_text}><h1>{t("check")?item.nameUz:item.nameRu}</h1></div>
            </div>
          </Col>)
        })
        :''}

  
</Row>
</div>
    </div>
    <Modal
        title={<h2 className={style.modalTitle}>{student!=null?t("check")?student.nameUz:student.nameRu:''}</h2>}
        centered
        visible={open}
        footer={false}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        width={1000}
      >
       <p className='salomText' style={{textAlign:'justify', lineHeight:"2", fontSize:'18px', textIndent:'50px'}} dangerouslySetInnerHTML={{__html:student!=null? t('lang') === 'uz' ? student.mainTextUz : student.mainTextRu:''}}></p>
      </Modal>
  </div>
  )
}
